<template>
  <HeaderBar />
  <div class="home-page">
    <v-container style="max-width: 100%; height: 100%;">
      <v-row class="justify-center">
        <img :style="imageSize" :src="require('@/assets/logo.png')" />
      </v-row>

      <v-row v-if="isMobile()" style="margin-top:300px;" class="grey-row justify-center">
        <v-col lg="7" sm="10">
          <ContentRowComponent :title="about.title" :body="about.body" :img="about.img"/>
        </v-col>
      </v-row>
      <v-row v-else style="margin-top:420px;" class="grey-row justify-center py-5">
        <v-col lg="8" sm="10">
          <ContentRowComponent :title="about.title" :body="about.body" :img="about.img"/>
        </v-col>
      </v-row>

      <v-divider></v-divider>

      <v-row class="grey-row justify-center py-5">
        <v-col lg="8" sm="10">
          <ContentRowComponent :title="dqc.title" :body="dqc.body" />
        </v-col>
      </v-row>

      <v-divider></v-divider>

      <v-row v-if="updates.length != 0" class="grey-row justify-center py-5">
        <v-col lg="8" sm="10">
          <LatestUpdates :updates="updates" />
        </v-col>
      </v-row>

      <v-divider v-if="updates.length != 0"></v-divider>

      <v-row class="grey-row justify-center py-5">
        <v-col lg="8" sm="10">
          <ContentRowComponent :title="contact.title" :body="contact.body" />
        </v-col>
      </v-row>
    </v-container>
  </div>

  <FooterBar />

</template>

<script>

import HeaderBar from './HeaderBar.vue'
import FooterBar from './FooterBar.vue'
import LatestUpdates from './LatestUpdates.vue'
import ContentRowComponent from './ContentRowComponent.vue'

export default {
  name: 'HomePage',
  data() {
    return {
      updates: [{
        id: 1,
        title: 'Qoro Quantum in the CDL Bootcamp',
        description: 'Qoro Quantum is excited to be part of the Creative Destruction Lab Quantum Bootcamp.',
        img: "https://creativedestructionlab.com/wp-content/uploads/2018/07/pink-black.jpg"
      },
      {
        id: 1,
        title: 'Qoro Quantum Joins the QBN',
        description: 'Qoro Quantum is excited to join the Quantum Business Network.',
        img: require("@/assets/qbn.png")
      }
      ],
      windowWidth: window.innerWidth,
      about: {
        title: "Multi-Node Quantum Computing Today",
        body: "Qoro Quantum builds the network stack to enable distributed quantum computing. Using classical networking technologies, we enable multi-node quantum computing. We develop the networking infrastructure required to process and distribute quantum algorithms across multiple quantum processors. Our technology is designed to be scalable, secure, and efficient, enabling the next generation of quantum computing today.",
        img: require('@/assets/dqc.png')        
      },
      dqc: {
        title: "Quantumly Distributed Quantum Computing",
        body: "Distributed quantum computing will enable scalable quantum computing, but will require complex quantum networking technologies. To achieve distributed quantum computing, quantum interconnect technologies will be needed, this includes quantum switches, quantum routers, tranducers, and other novel techologies. There will be a need to reliably distribute entanglement across the network which involves scheduling and precise network control. Leading up to this, the classical networking infrastructure and standards will be needed. Qoro Quantum takes the first step in developing the network stack leading up to quantumly-connected distributed quantum computing"
      },
      contact: {
        title: "Contact Us",
        body: "For inquiries, partnerships, or general information, please contact us at: team@qoroquantum.de"
      }
    }
  },
  components: {
    HeaderBar,
    FooterBar,
    LatestUpdates,
    ContentRowComponent
  },
  methods: {
    isMobile() {
      return (screen.width <= 760);
    }
  },
  computed: {
    imageSize() {
      if (this.isMobile()) {
        return 'width:70%';
      } else if (this.windowWidth < 1000) {
        return 'width:70%';
      } else {
        return 'width:60%';
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.home-page {
  width: 100%;
  background-color: #000;
  display: flex;
  align-items: start;
  justify-content: center;
  padding-top: 250px;
}

.grey-row {
  background-color: #F2F2F2;
}
</style>