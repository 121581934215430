<template>
    <v-container class="header">
        <v-row justify="start">
            <v-col lg="1" sm="2">
                <v-img class="cursor-pointer" src="@/assets/logo_small.png" height="25px" @click="goHome" />
            </v-col>
            <v-col lg="1" :offset="offset" sm="2">
                <v-label v-if="isHome" class="text-white cursor-pointer header-label" @click="scrollTo(600)">
                    About
                </v-label>
                <v-label v-else class="text-white cursor-pointer header-label" @click="goHome">
                    About
                </v-label>
            </v-col>
            <v-col lg="1" sm="2">
                <v-label v-if="isHome" class="text-white cursor-pointer header-label" @click="scrollTo(1000)">
                    News
                </v-label>
                <v-label v-else class="text-white cursor-pointer header-label" @click="goHome">
                    News
                </v-label>
            </v-col>
            <v-col lg="1" sm="2">
                <!-- <v-label class="text-white cursor-pointer header-label">
                    <router-link style="text-decoration: none; color: inherit;" to="/contact">Contact</router-link>
                </v-label> -->
                <v-label v-if="isHome" class="text-white cursor-pointer header-label" @click="scrollTo(1000)">
                    Contact
                </v-label>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import router from '../router';

export default {
    name: 'HeaderBar',
    data() {
        return {
            windowSize: window.innerWidth
        }
    },
    methods: {
        scrollTo(height) {
            window.scrollTo({
                top: height,
                behavior: 'smooth'
            });
        },
        goHome() {
            router.push('/');
        },
        handleResize() {
            this.windowSize = window.innerWidth;
        }
    },
    computed: {
        isHome() {
            return this.$route.path === '/';
        },
        isMobile() {
            return (screen.width <= 760);
        },
        offset() {
            if (this.windowSize <= 700) {
                return 2;
            } else if (this.windowSize <= 1200) {
                return 4;
            } else {
                return 8;
            }
        }
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }
}
</script>

<style scoped>
.header {
    background-color: #000;
    width: 100%;
    margin: 0;
    max-width: 100%;
    position: fixed;
    z-index: 10;
}

.header-label {
    font-size: 20px;
    font-weight: 500;
    color: #2A3640;
}
</style>