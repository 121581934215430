<template>
    <v-container class="updates">
        <h2 class="header-text text-black"> Latest Updates </h2>
        <v-container>
            <v-row justify="center">
                <v-col lg="6" sm="12" v-for="update in updates" :key="update.id">
                    <UpdateComponent :update="update" />
                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>

<script>
import UpdateComponent from './UpdateComponent.vue';

export default {
    name: 'LatestUpdates',
    components: {
        UpdateComponent
    },
    props: {
        updates: {
            type: Array,
            required: true
        }
    }
}
</script>

<style scoped>
.updates {
    margin: 0;
    max-width: 100%;
}


.header-text {
    color: #2A3640;
}
</style>